import React from "react"

import Layout from "../../components/layoutHome"
import SEO from "../../components/seo"
import { useStaticQuery, graphql } from "gatsby";
import {Grid} from '@material-ui/core'
import Office from '../../components/Models/oficinas'

const Oficinas = () => {
  const data= useStaticQuery(graphql`query offices {
    allCeformaPagesShow5 {
      edges {
        node {
          data {
            page {
              content {
                Oficinas {
                  Direccion
                  Nombre
                  Telefono
                  Imagenes {
                    Url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  `)
  console.log(data)
return (
  <Layout>
    <SEO title="Oficinas" />
    <h1>Estamos Para Servirte</h1>
    <Grid 
      container
      direction="row"
      justify="center"
      alignItems="center"
      spacing={3}
      >
        {data.allCeformaPagesShow5.edges[0].node.data.page.content.Oficinas.map((office, index)=>{
          return (
            <Grid xs={12}
            style={{
              marginLeft:"5%",
              marginRight:"5%"
            }}
            >
              <Office office={office} map= {index}/>
            </Grid>)
        })
        }
    </Grid>
  </Layout>
)}

export default Oficinas

import React from "react"
import {Typography, Card, CardMedia, Grid} from '@material-ui/core'
import BusinessIcon from '@material-ui/icons/Business';
import PhoneIcon from '@material-ui/icons/Phone';
import { makeStyles} from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
      media: {
        height: 230,
        width: 250,
        borderRadius: 6,
        margin: 20
      },
      card:{
          margin: 10,
      },
      map:{
          //width: '50%'
      },
      containerTitle:{
          backgroundColor: '#666666',
          background: '#404040',
          minHeight:'38px'
      },
      title:{
          color: 'white',
          fontWeight: 'bold',
          fontSize: '1.4em',
          paddingLeft: 20
      },
      text: {
          paddingTop: 15,
          paddingLeft: 20,
          paddingRight: 15,
          whiteSpace:"pre",
          '@media (max-width: 477px)': {
            whiteSpace:"pre",
          },
      }
}));

const OfficeTemplate= ({office, map}) =>{
    //console.log(office, map)
    const classes = useStyles();
    
    const mapOffice =(officeName)=>
    {
        switch(officeName){
            case 'IRAPUATO': return (<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7466.356756458562!2d-101.31688987304688!3d20.66232067275215!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842c80f108d411a1%3A0x696683c9305501f2!2sExcelsior%2C%20Sin%20Nombre%2C%20Irapuato%2C%20Gto.!5e0!3m2!1sen!2smx!4v1592187742101!5m2!1sen!2smx" width="100%" height="400vh" frameborder="0" style={{border:0}} allowfullscreen="" aria-hidden="false" title= 'Irapuato'></iframe>)
            case 'CELAYA': return(<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7473.209583809324!2d-100.81320511114502!3d20.522421210171995!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842cba85cc9020eb%3A0xe2b75ab6c49daca3!2sR%C3%ADo%20Bravo%20107%2C%20Col.%20Centro%2C%2038000%20Celaya%2C%20Gto.!5e0!3m2!1sen!2smx!4v1592194203412!5m2!1sen!2smx" width="100%" height="400vh" frameborder="0" style={{border:0}} allowfullscreen="" aria-hidden="false" title= 'CELAYA'></iframe>)
            case 'SILAO': return(<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d931.5703045766606!2d-101.43127724669647!3d20.941218889071838!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842b9c81770371d7%3A0xb9aa4176d67f0bc!2sLic.%20Benito%20Ju%C3%A1rez%2065%2C%20Centro%2C%2036100%20Silao%2C%20Gto.!5e0!3m2!1sen!2smx!4v1592194393764!5m2!1sen!2smx" width="100%" height="400vh" frameborder="0" style={{border:0}} allowfullscreen="" aria-hidden="false" title= 'SILAO'></iframe>)
            case 'VILLAGRÁN': return(<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2221.8717724521957!2d-100.99889747987889!3d20.51737295872284!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842c97c5e06896cd%3A0x25636bc8a705e919!2s16%20de%20Septiembre%20909%2C%20Zona%20Centro%2C%2038260%20Villagr%C3%A1n%2C%20Gto.!5e0!3m2!1sen!2smx!4v1592194461603!5m2!1sen!2smx" width="100%" height="400vh" frameborder="0" style={{border:0}} allowfullscreen="" aria-hidden="false" title= 'VILLAGRÁN'></iframe>)
            case 'SAN_JOSÉ_ITURBIDE': return(<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1862.4621909023574!2d-100.39026895966337!3d20.995668091286117!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d4bb1c01b4aa57%3A0xa8b32e85e4096c10!2sIturbide%2042%2C%20Zona%20Centro%2C%2037980%20San%20Jos%C3%A9%20Iturbide%2C%20Gto.!5e0!3m2!1sen!2smx!4v1592194518278!5m2!1sen!2smx" width="100%" height="400vh" frameborder="0" style={{border:0}} allowfullscreen="" aria-hidden="false" title= 'SAN_JOSÉ_ITURBIDE'></iframe>)
            default: return (<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7466.356756458562!2d-101.31688987304688!3d20.66232067275215!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842c80f108d411a1%3A0x696683c9305501f2!2sExcelsior%2C%20Sin%20Nombre%2C%20Irapuato%2C%20Gto.!5e0!3m2!1sen!2smx!4v1592187742101!5m2!1sen!2smx" width="100%" height="400vh" frameborder="0" style={{border:0}} allowfullscreen="" aria-hidden="false" title= 'Irapuato'></iframe>)
        }
    }
    return(
        <Card className= {classes.card}>
            <Grid xs={12}className={classes.containerTitle}
            container
            alignItems="center">
                <Typography className={classes.title}>{office.Nombre}</Typography>
            </Grid>
            <Grid container
            direction="column"
            justify="center"
            alignItems="center">
                <Grid  container
                direction="row"
                justify="center"
                alignItems="flex-start">
                    <Grid xs={12} sm={12} md={8} lg={8} xl={8}  className={classes.map}>
                    {mapOffice(office.Nombre.toUpperCase().replace(/ /g, "_"))}
                    </Grid>
                    <Grid xs={12} sm={12} md={4} lg={4} xl={4} >
                        <Typography className={classes.text}><BusinessIcon/> {" "+"Direccion"}</Typography>
                        <Typography className={classes.text}>{office.Direccion}</Typography>
                        <Typography className={classes.text}><PhoneIcon/> {" "+"Telefono"}</Typography>
                        <Typography className={classes.text}>{office.Telefono}</Typography>
                    </Grid>
                </Grid>
                <Grid container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={3}>
                    {
                        office.Imagenes.map((imagen, index)=>(
                            <CardMedia 
                            image={imagen.Url}
                            title={office.Nombre+ '-'+index}
                            className={classes.media}
                            />
                        ))
                    }
                </Grid>
            </Grid>
        </Card>)
}
export default OfficeTemplate